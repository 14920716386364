.navbar{
    display: flex;
    flex-direction: column;
}

.navbar a {
    text-decoration: none;
    color: #718096;
    background-color: transparent;
    margin: 10px 0px;
    padding: 12px 20px;
    border-radius: 5px;
    font-weight: 700;
    width: 150px;
}

.navbar-button{
    background-color: white;
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    position: absolute;
    bottom: 50px;
    width: 150px;
    text-align: left;
    font-weight: 700;
    color: #101010;
    font-size: 20px;
    cursor: pointer;
    border: 1px solid white;
    transition: background-color .2s ease, color .2s ease;
}

.navbar-button:hover{
    background-color: transparent;
    color: white;

}

.navbar{
    & .navbar-links.active{
        color: #101010;
        background-color: var(--primary-color);
    }
}

