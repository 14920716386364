body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-background);
}

h1,h2,h3,h4,h5,h6{
  font-family: 'League Spartan', sans-serif;

}

p, div{
  font-family: Helvetica, sans-serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --primary-background: #101010;
  --secondary-background: #1F2937;
  --primary-color: #8C52FF;
  --error-color: #EE1D52;
  --text-color-primary: #D8DFEE;
  --text-color-inactive: #718096;

  --publish-button-primary: #35E526;
  --publish-button-background: #0E2B11;


  --delete-button-primary: #E52626;
  --delete-button-background: #2B0E0E;
  
  --draft-color: #E58D26;
  --archive-color: #BF26E5;
  --edit-color: #26A1E5;
  --edit-color-background: #0E182B;

}

.add-button{
  margin-left: auto;
  color: var(--text-color-inactive); 
  background-color: var(--primary-background);
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
}

.table-row{
  height: 50px;
}

table{
  width: 100%;
}

th{
  color: var(--text-color-inactive);
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}

.title-data, td{
  text-decoration: none;
  color: var(--text-color-primary);

}

.title-data:hover{
  text-decoration: underline;
}

th,td{
  border-bottom: 1px solid var(--primary-background);
  padding: 15px 30px;
}



.delete-button{
  color: var(--delete-button-primary);
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: var(--delete-button-background);
  border: 1px solid var(--delete-button-background);
  cursor: pointer;
}

.delete-button:hover{
  border-color: var(--delete-button-primary);
}
.delete-button:active{
  border-color: var(--delete-button-background);
  color: #e5262630;
}
.publish-button{
  color: var(--publish-button-primary);
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  background-color: var(--publish-button-background);
  border: 1px solid var(--publish-button-background);
  cursor: pointer;
}

.publish-button:hover{
  border-color: var(--publish-button-primary);
}
.publish-button:active{
  border-color: var(--publish-button-background);
  color: #36e52630;
}

.archive-button{
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--archive-color);
  background-color: #220E2B;
  border: 1px solid #220E2B;
}
.archive-button:hover{
  border-color: var(--archive-color);
}
.archive-button:active{
  border-color: #220E2B;
  color: #bf26e530;
}

.edit-button{
  padding: 12px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--edit-color);
  background-color: var(--edit-color-background);
  border: 1px solid var(--edit-color-background);
}

.edit-button:hover{
  border-color: var(--edit-color);
}
.edit-button:active{
  border-color: var(--edit-color-background);
  color: #26a2e530;
}
